.product-card {
    display: inline-block;
    width: 200px;
  }
  
  .product-container-wrapper {
    position: relative;
    overflow: hidden;
  }
  
  .product-container {
    white-space: nowrap;
  }
  
  .scroll-button {
    position:absolute;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 24px;
    cursor: pointer;
    text-decoration: none;
    margin-top:100px;
  }
  
  .scroll-button.left {
    left: 30px;
  }
  
  .scroll-button.right {
    right: 30px;
  }

  .focused {
    width:200px;
    transition: all 0.3s ease-in-out;
  }
  
  .menu{
    width:0%;
    opacity:0%
  }
  
  .clicked {
    margin-left:0;
    width:60%;
    transition: all 0.3s ease-in-out;
    opacity:100%
  }

  @media only screen and (max-width: 767px) {
    .mobileview {
      display: flex;
    }
    .compview {
      display: none;
    }
  }
  
  @media screen and (min-width: 768px) {
    .mobileview {
      display: none;
    }
    .compview {
      display: flex;
    }
  }
  
    